@font-face {
  font-family: "Nexa";
  src: local("Nexa Black"), local("Nexa--Black"),
    url("./fonts/Nexa-Black.woff2") format("woff2"),
    url("./fonts/Nexa-Black.woff") format("woff"),
    url("./fonts/Nexa-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
strong {
  font-family: "Roboto", sans-serif !important;
}

@import "~react-pro-sidebar/dist/scss/styles.scss";

body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: "Roboto", sans-serif !important;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

#root {
  height: 100%;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 760px) {
  .mobile {
    display: flex !important;
  }
  .desktop {
    display: none !important;
  }
}

.fullpage {
  background: url("./bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.add-to-home-screen-pwa {
  z-index: 99999;
}

.add-to-home-screen-pwa__notify {
  button {
    padding: 11px 45px!important;
    border: 2px solid #bfd7ea!important;
    border-radius: 27px!important;
    transition: color 0.4s linear!important;
    position: relative!important;
    overflow: hidden!important;
    font-family: "Roboto", Arial, sans-serif!important;
    background: #bfd7ea!important;
    color: #333!important;
    padding: 20px 48px!important;
    border-radius: 33px!important;
    z-index: 99999!important;
    border: none!important;
  }
}

.intro {
  height: 100%;
  background: #00a7a6; 
  margin: 0;
  padding: 5rem 2rem 0;
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 900;
    margin: 1rem auto;
    color: #222;
    font-family: "Nexa", sans-serif !important;
  }
  h2 {
    font-size: 2rem !important;
    line-height: 2rem;
    font-weight: 900;
    margin: 0 auto 2rem;
    color: #d2e2ed;
    font-family: "Nexa", sans-serif !important;
  }
  h6 {
    font-weight: 900;
    margin-bottom: 1.5rem;
  }
  p {
    color: white;
  }
}

@media (max-width: 760px) {
  .intro {
    z-index: 101;
    h1 {
      font-size: 33px;
      line-height: 40px;
      font-weight: 900;
      margin: 1rem 4rem;
      color: #222;
      font-family: "Nexa", sans-serif !important;
    }
    h2 {
      font-size: 2rem !important;
      line-height: auto;
      font-weight: 900;
      color: #d2e2ed;
      font-family: "Nexa", sans-serif !important;
      margin: 0 2rem 2rem;
    }
    h6 {
      font-weight: 900;
      margin-bottom: 1.5rem;
      margin: 2rem 3rem;
    }
  }
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    position: absolute;
    width: 33px;
    height: 33px;
    background: #525f64;
    color: #fff;
    margin: 10px;
    padding: 5px;
    text-align: center;
    border-radius: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .sidebar-btn {
      transition: width 0.3s;
      width: 90%;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    width: 100% !important;
    flex-grow: 1;
    // display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      // margin-bottom: 40px;
      // border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: 100px;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .section {
      display: grid;
      justify-content: center;
      align-content: center;
      // height: 100vh !important;
      text-align: center;
      padding: 5rem;
      background: #e3e3e3;
      color: #333;
      div {
        width: 100%;
        padding: 3rem;
        border-radius: 20px;
        text-align: left;
      }
      h1 {
        font-size: 3.7rem;
        line-height: 5rem;
        font-weight: 900;
        color: #000;
      }
      h2 {
        font-size: 2.3rem;
        line-height: 3rem;
        font-weight: 900;
        margin: 1rem auto 2rem;
        color: #dc2782;
      }
      p {
        font-size: 1.5rem;
        width: 80%;
        margin: 0 auto;
        line-height: 2rem;
      }
    }

    .bg {
      background: url("https://images.prismic.io/safetytoolkit/86938a7a-6cdb-493d-9c2a-7c59f808a15a_Committee-to-Protect-Journalists-Journalist-Journalism-safety-newspaper-reporter.jpeg")
        top center no-repeat;
      background-size: cover;
    }

    @media (max-width: 760px) {
      .section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        height: 100vh;
        text-align: center;
        padding: 0;
        color: white;
        h1 {
          font-size: 2.3rem;
          line-height: 3rem;
          font-weight: 900;
          color: #000;
        }
        h2 {
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 900;
          margin: 1rem auto 2rem;
          color: #dc2782;
        }
        p {
          font-size: 17px;
          line-height: 20px;
          width: 100%;
          margin: 0 auto;
          line-height: 2rem;
        }
        div {
          height: 100%;
          width: 100%;
          margin: 10px auto;
          padding: 30px;
        }
      }
    }

    .section_title {
      font-size: 4rem;
      font-weight: 900;
    }

    .section__heading {
      font-weight: 900;
      font-size: 2rem;
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 16px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
    footer {
      display: none !important;
    }
  }
  @media (max-width: 1280px) {
    .pro-sidebar-footer {
      display: none !important;
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background: #525f64;
  a {
    color: white !important;
  }
}
.pro-sidebar {
  color: white !important;
  font-weight: 500;
  a {
    color: white !important;
    font-weight: 500;
  }
}
h4 {  font-family: 'Nexa', sans-serif!important;
  font-size: 28px !important;
  line-height: 40px;
  padding-top: 20px;
  color: #dc2782;
  text-transform: capitalize;
}

h5 {
  font-family: "Nexa", sans-serif!important;
  font-size: 1.25rem;
  padding-top: 20px;
  margin: 0;
  font-weight: 900 !important;
}

main,
.pro-sidebar {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
